import React, { Suspense } from "react";
import { useAssignSettingImprovedFoiExperience, useSettingImprovedFoiExperience } from "@faire/retailer/settings/useSettingImprovedFoiExperience";
import { LazyFirstOrderIncentiveFab } from "./LazyFirstOrderIncentiveFab";
import { route as cartRoute } from "@faire/web-api/routes/cart";
import { route as checkoutRoute } from "@faire/web-api/routes/checkout";
import { route as userAccountRoute } from "@faire/web-api/routes/user/account";
import { route as messagesRoute } from "@faire/web-api/routes/messages";
import { route as boardsRoute } from "@faire/web-api/routes/boards";
import { route as pointOfSaleRoute } from "@faire/web-api/routes/point-of-sale";
import { route as netTermsRoute } from "@faire/web-api/routes/net-terms";
import { route as openWithFaireRoute } from "@faire/web-api/routes/open-with-faire";
import { route as userBrandReferralsRoute } from "@faire/web-api/routes/user/brand-referrals";
import { route as marketsRoute } from "@faire/web-api/routes/markets";
import { route as brandsRoute } from "@faire/web-api/routes/brands";
import { route as smallBusinessGrantRoute } from "@faire/web-api/routes/small-business-grant";
import { route as retailerSettingsStoresRoute } from "@faire/web-api/routes/retailer/settings/stores";
import { route as retailerSettingsMyTeamRoute } from "@faire/web-api/routes/retailer/settings/my-team";
import { route as retailerSettingsPaymentMethodsRoute } from "@faire/web-api/routes/retailer/settings/payment-methods";
import { route as retailerSettingsShippingPreferencesRoute } from "@faire/web-api/routes/retailer/settings/shipping-preferences";
import { route as retailerSettingsProfileRoute } from "@faire/web-api/routes/retailer/settings/profile";
import { route as retailerSettingsEmailPreferencesRoute } from "@faire/web-api/routes/retailer/settings/email-preferences";
import { route as retailerSettingsSecurityRoute } from "@faire/web-api/routes/retailer/settings/security";
import { route as retailerSettingsPosManagerRoute } from "@faire/web-api/routes/retailer/settings/pos-manager";
import { route as retailerSettingsInsiderMembershipRoute } from "@faire/web-api/routes/retailer/settings/insider-membership";
import { route as retailerSettingsDeactivateAccountRoute } from "@faire/web-api/routes/retailer/settings/deactivate-account";
import { route as retailerSettingsAvailableCreditRoute } from "@faire/web-api/routes/retailer/settings/available-credit";
import { route as retailerSettingsMyAddressesRoute } from "@faire/web-api/routes/retailer/settings/my-addresses";
import { useLocation } from "react-router-dom";
import { RetailerRoute } from "@faire/web/common/routes/RetailerRouteMatcher";
import { RouteMatcher } from "@faire/web/common/routes/RouteMatcher";
import { useFirstOrderIncentiveStore } from "@faire/retailer/hooks/firstOrderIncentive/useFirstOrderIncentiveStore";
import { useDismissFOIModalMilestones } from "@faire/retailer/hooks/firstOrderIncentive/useDismissFOIModalMilestones";
import { useSettingRemoveMessengerFAB } from "@faire/retailer/settings/useSettingRemoveMessengerFAB";

const FOI_FAB_BLOCKED_PATHS: RetailerRoute[] = [
  cartRoute,
  checkoutRoute,
  userAccountRoute,
  messagesRoute,
  boardsRoute,
  pointOfSaleRoute,
  netTermsRoute,
  openWithFaireRoute,
  userBrandReferralsRoute,
  marketsRoute,
  brandsRoute,
  smallBusinessGrantRoute,
  retailerSettingsStoresRoute,
  retailerSettingsMyTeamRoute,
  retailerSettingsPaymentMethodsRoute,
  retailerSettingsShippingPreferencesRoute,
  retailerSettingsProfileRoute,
  retailerSettingsEmailPreferencesRoute,
  retailerSettingsSecurityRoute,
  retailerSettingsPosManagerRoute,
  retailerSettingsInsiderMembershipRoute,
  retailerSettingsDeactivateAccountRoute,
  retailerSettingsAvailableCreditRoute,
  retailerSettingsMyAddressesRoute,
];

export const FirstOrderIncentiveFabWrapper: React.FC = () => {
  const { pathname } = useLocation();
  const isMessengerFabRemoved = useSettingRemoveMessengerFAB();
  const isImprovedFoiExperience = useSettingImprovedFoiExperience();
  const assignImprovedFoiExperience = useAssignSettingImprovedFoiExperience();
  const { isFirstOrderForFaireDirectIncentive, isRetailerFoiEligible, isLastChanceIncentive } = useFirstOrderIncentiveStore();
  const { foiModalDismissCount, lastChanceFoiModalDismissCount } = useDismissFOIModalMilestones();

  const excludedRouteMatcher: RouteMatcher<RetailerRoute> = new RouteMatcher(FOI_FAB_BLOCKED_PATHS);
  const isLocationBlocked = !!excludedRouteMatcher.match(pathname);
  const hasDimissedInitialModal = isLastChanceIncentive ? lastChanceFoiModalDismissCount > 0 : foiModalDismissCount > 0;

  // precondition: REMOVE_MESSENGER_FAB_241112 is true, since FOI FAB and Messenger FAB take the same real estate
  const shouldAssign = !isFirstOrderForFaireDirectIncentive && isRetailerFoiEligible && !isLocationBlocked && isMessengerFabRemoved && hasDimissedInitialModal;
  const canShow = shouldAssign && isImprovedFoiExperience;

  if (shouldAssign) {
    assignImprovedFoiExperience();
  }

  if (!canShow) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyFirstOrderIncentiveFab />
    </Suspense>
  );
};
