import messages from "@faire/web-api/routes/messages";
import userBrandReferralsPreviewReferralEmail from "@faire/web-api/routes/user/brand-referrals/preview-referral-email";
import { useMemo } from "react";

import { useZustandLocation } from "@faire/retailer/app/_lib/routing/useZustandLocation";

export const useShouldApplyLayout = () => {
  const { pathname } = useZustandLocation((state) => state.pathname);
  return useMemo(
    () =>
      !pathname.startsWith(messages()) &&
      !pathname.startsWith("/embed") &&
      !pathname.startsWith(userBrandReferralsPreviewReferralEmail()),
    [pathname]
  );
};
