import { useQuery as useGetConductorComponentQuery } from "@faire/web-api/api/conductor/component/get-hooks";
import { QueryParameters as ConductorComponentQueryParameters } from "@faire/web-api/api/conductor/component/get";
import { ConductorSurface } from "@faire/web-api/indigofair/data/ConductorSurface";
import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";

export const useConductorComponent = (
  surface: keyof typeof ConductorSurface | undefined
) => {
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const queryParams = ConductorComponentQueryParameters.build({
    surface,
  });
  const { data, isPending } = useGetConductorComponentQuery(queryParams, {
    enabled: isLoggedInRetailer,
  });

  const conductorSduiViewLayout = data?.component?.sdui_component?.view_layout;
  const conductorBuilderModelName = data?.component?.builder_component?.builder_model_name;
  const shouldShowConductor = !!conductorSduiViewLayout || !!conductorBuilderModelName;

  return {
    shouldShowConductor: shouldShowConductor,
    conductorSduiViewLayout: conductorSduiViewLayout,
    conductorBuilderModelName: conductorBuilderModelName,
    isConductorComponentPending: isPending,
  };
};
