import { CartsTabs } from "@faire/retailer/stores/domain/CartStore/consts";

import { CartSortByValue } from "../components/CartSortPill/types";

import {
  BrandCartsTransitionState,
  BrandCartTransitionType,
  CartPageStore,
} from "./types";

export const INITIAL_BRAND_CART_TRANSITION_STATE: BrandCartsTransitionState = {
  brandCarts: [],
  type: BrandCartTransitionType.NONE,
};

export const INITIAL_CART_PAGE_STORE: CartPageStore = {
  // Search & filter states
  activeTab: CartsTabs.ALL_BRAND,
  searchFieldValue: "",
  sortByValue: CartSortByValue.NEWEST,
  isFilterByOverMinimum: false,
  isFilterByUnderMinimum: false,
  brandTokenOrder: undefined,
  // Transition states
  brandCartTransitionState: {
    brandCarts: [],
    type: BrandCartTransitionType.NONE,
  },
  isBrandCartTransitioning: false,
  brandCartDetailsShownMap: new Map(),
  brandCartMovingMap: new Map(),
  deletedBrandCartDataMap: new Map(),
  selectedBrandTokens: [],
  focusedBrandToken: undefined,
  brandCartRefs: { current: {} },
  // Mobile states
  showingMobileDetailsPageForBrand: undefined,
  // Error states
  brandsWithShippingDateIssue: [],
  errorMessage: undefined,
  // Toast states
  isUndoToastVisible: false,
  // Initialization
  hasPageLoaded: false,
  hasInitializedDetailsShownMap: false,
};
