"use client";

import { getWindow } from "@faire/web/common/globals/getWindow";
import { RoutingContext } from "@faire/web/ui/routing/RoutingContext";
import {
  getLocalePrefix,
  removeLocaleFromUrl,
} from "@faire/web/ui/routing/util";
import { LocationDescriptorObject, MemoryHistory, parsePath } from "history";
import { usePathname } from "next/navigation";
import React, { useCallback, useContext, useLayoutEffect, useRef } from "react";
import { Router } from "react-router-dom";

import { getShouldRequestRSCOnSearchPage } from "@faire/retailer/app/_lib/routing/__internal__/getShouldRequestRSCOnSearchPage";
import {
  IUrl,
  sanitizeUrl,
} from "@faire/retailer/app/_lib/routing/sanitizeUrl";
import { useRouter } from "@faire/retailer/app/_lib/routing/useRouter";
import { useSearchParamsString } from "@faire/retailer/app/_lib/routing/useSearchParamsString";

import { getShouldRequestRSCOnBrandPage } from "./__internal__/getShouldRequestRSCOnBrandPage";

// React router uses function overloading instead of union types,
// so we need to define our own type here
type ReactRouterOperation = (
  path: string | LocationDescriptorObject<unknown>,
  state: unknown
) => void;

export const ReactRouterCompat = ({
  memoryHistory,
  children,
}: {
  memoryHistory: MemoryHistory;
  children: React.ReactNode;
}) => {
  const appRouter = useRouter();
  const routeContext = useContext(RoutingContext);

  const pathname = usePathname();
  const searchParamsString = useSearchParamsString();
  const originalReactRouterMethods = useRef({
    push: memoryHistory.push as ReactRouterOperation,
    replace: memoryHistory.replace as ReactRouterOperation,
    goBack: memoryHistory.goBack,
  });

  /**
   * When a direct load occurs at "/category," the middleware will correct it to
   * "/{actual locale}/category" before requesting the RSC payload. However, the
   * browser history will not include the locale.
   *
   * Upon a route change, formatLocalePrefixInUrl will add the locale to the URL,
   * resulting in "/{actual locale}/category." This URL is then forwarded as is
   * to retrieve the RSC payload.
   *
   * This approach ensures that the browser pathname remains consistent with the
   * RSC payload request, which is a requirement for RSC.
   *
   * To avoid adding redundant "en-us" to the URL, especially since it is the
   * majority case, we will intentionally remove "/en-us" from the URL even if
   * it is specified. This removal is based on the convention that an empty string
   * represents "en-us."
   */
  const formatLocalePrefixInUrl = useCallback(
    (url: IUrl) => {
      const pathnameWithLocale = `${getLocalePrefix(
        routeContext?.locale,
        routeContext?.localeCountryUrlPrefix,
        routeContext?.allowedRobotLocales
      )}${removeLocaleFromUrl(sanitizeUrl({ pathname: url.pathname }))}`;
      return sanitizeUrl({
        ...url,
        pathname: pathnameWithLocale,
      });
    },
    [
      routeContext?.allowedRobotLocales,
      routeContext?.locale,
      routeContext?.localeCountryUrlPrefix,
    ]
  );

  /**
   * We use a combination of React Router's memoryHistory.location.pathname and
   * Next.js' usePathname to determine resulting logic here. This is because while
   * usePathname is async (and therefore possibly out of date with React Router),
   * it IS in sync with Next.js' internal routing state (whereas
   * memoryHistory.location.pathname isn't). If we were to use the more "up to date"
   * memoryHistory.location.pathname, we would introduce the possibility of not performing
   * an RSC request when we actually want to. In essence, usePathname should be used when
   * determining actions involving Next.js' router, whereas memoryHistory.location.pathname
   * should be used when you want the current state of the react app (i.e. useHistory).
   */
  const determineSyncActions = useCallback(
    (path: string | LocationDescriptorObject<unknown>, state?: unknown) => {
      let location: LocationDescriptorObject<unknown> | undefined;
      let fetchFromServer = false;
      if (typeof path === "string") {
        location = parsePath(path);
        if (
          state &&
          typeof state === "object" &&
          "fetchFromServer" in state &&
          typeof state.fetchFromServer === "boolean"
        ) {
          fetchFromServer = state.fetchFromServer;
          delete state.fetchFromServer;
        }
      } else {
        /**
         * React Router allows location object to miss pathname, in which case
         * the current pathname is used. Example:
         *
         * history.replace({ search: "" });
         */
        location = {
          pathname: memoryHistory.location.pathname,
          ...path,
        };

        if (
          location.state &&
          typeof location.state === "object" &&
          "fetchFromServer" in location.state &&
          typeof location.state.fetchFromServer === "boolean"
        ) {
          fetchFromServer = location.state.fetchFromServer;
          delete location.state.fetchFromServer;
        }
      }
      const isSamePage = isPathnameEqual(location.pathname ?? "", pathname);
      const shouldRequestRSCOnSearchPage = getShouldRequestRSCOnSearchPage({
        prevPathname: pathname,
        prevSearch: searchParamsString,
        pathname: location.pathname ?? "",
        search: location.search ?? "",
      });
      const shouldRequestRSCOnBrandPage = getShouldRequestRSCOnBrandPage({
        prevSearch: searchParamsString,
        pathname: location.pathname ?? "",
        search: location.search ?? "",
      });

      // Whether or not we want to request the RSC payload when we navigate.
      const shouldRequestRSC =
        !isSamePage ||
        fetchFromServer ||
        shouldRequestRSCOnSearchPage ||
        shouldRequestRSCOnBrandPage;
      const urlWithLocale = formatLocalePrefixInUrl(location);
      return {
        shouldRequestRSC,
        isSamePage,
        urlWithLocale,
      };
    },
    [
      formatLocalePrefixInUrl,
      memoryHistory.location.pathname,
      pathname,
      searchParamsString,
    ]
  );

  // Override the memoryHistory methods to ensure that the browser history and app router stays in sync.
  useLayoutEffect(() => {
    const history = getWindow()?.history;
    if (!history) {
      return;
    }

    // Handle useHistory().push
    memoryHistory.push = (
      path: string | LocationDescriptorObject<unknown>,
      state?: unknown
    ) => {
      const { shouldRequestRSC, isSamePage, urlWithLocale } =
        determineSyncActions(path, state);

      // React Router action
      originalReactRouterMethods.current.push.call(
        memoryHistory,
        urlWithLocale,
        state
      );

      // NextJS Shallow Navigation
      history.pushState.call(history, null, "", urlWithLocale);

      // NextJS RSC navigation
      if (shouldRequestRSC) {
        appRouter.push(urlWithLocale, { scroll: !isSamePage });
      }
    };

    // Handle useHistory().replace
    memoryHistory.replace = (
      path: string | LocationDescriptorObject<unknown>,
      state?: unknown
    ) => {
      const { shouldRequestRSC, isSamePage, urlWithLocale } =
        determineSyncActions(path, state);

      // React Router action
      originalReactRouterMethods.current.replace.call(
        memoryHistory,
        urlWithLocale,
        state
      );

      // NextJS Shallow Navigation
      history.replaceState.call(history, null, "", urlWithLocale);

      // NextJS RSC navigation
      if (shouldRequestRSC) {
        appRouter.replace(urlWithLocale, { scroll: !isSamePage });
      }
    };
    memoryHistory.goBack = () => {
      originalReactRouterMethods.current.goBack.call(memoryHistory);
      appRouter.back();
    };
    const originalRouterMethods = originalReactRouterMethods.current;
    return () => {
      memoryHistory.push =
        originalRouterMethods.push as typeof memoryHistory.push;
      memoryHistory.replace =
        originalRouterMethods.replace as typeof memoryHistory.replace;
      memoryHistory.goBack = originalRouterMethods.goBack;
    };
  }, [appRouter, determineSyncActions, memoryHistory]);

  /**
   * memoryHistory does not change when the user clicks the back button
   * in the browser, but nextjs does. This effect ensures that the memoryHistory stays in sync.
   */
  useLayoutEffect(() => {
    if (
      !isPathnameEqual(memoryHistory.location.pathname, pathname) ||
      !isSearchEqual(memoryHistory.location.search, searchParamsString)
    ) {
      originalReactRouterMethods.current.replace.call(
        memoryHistory,
        sanitizeUrl({
          pathname,
          search: searchParamsString,
        }),
        memoryHistory.location.state
      );
    }
  }, [memoryHistory, pathname, searchParamsString]);

  return <Router history={memoryHistory}>{children}</Router>;
};

const isPathnameEqual = (a: string, b: string) => {
  const processPathname = (rawPathname: string) => {
    // Comparison agnostic of locale prefix
    const decoded = decodeURI(removeLocaleFromUrl(rawPathname) || "/");
    if (decoded.endsWith("/")) {
      // Comparison agnostic of trailing slash
      return decoded.slice(0, -1);
    }
    return decoded;
  };
  return processPathname(a) === processPathname(b);
};

const isSearchEqual = (a: string, b: string) => {
  const processSearchParams = (rawSearchParams: string) => {
    let searchParams = rawSearchParams;
    if (searchParams.startsWith("?")) {
      // comparison agnostic of leading question mark
      searchParams = searchParams.slice(1);
    }
    return decodeURI(searchParams);
  };

  return processSearchParams(a) === processSearchParams(b);
};
