import React, { createContext, useContext } from "react";

import { useRouter } from "@faire/retailer/app/_lib/routing/useRouter";

import { ModifiedNextRouter } from "./types";

const NextPrefetchContext = createContext<ModifiedNextRouter["prefetch"]>(
  () => {}
);

export const NextPrefetchContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  return (
    <NextPrefetchContext.Provider value={router.prefetch}>
      {children}
    </NextPrefetchContext.Provider>
  );
};

/**
 * This is a temporary hook that allows us to access the NextJS router.
 * In the legacy app, this will return `undefined`.
 *
 * Once we ship NextJS to 100%, we can remove this in favour of
 * `useRouter` from `next/router`.
 */
export const useNextPrefetchContext = () => useContext(NextPrefetchContext);
