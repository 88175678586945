import { useViewport } from "@faire/web/ui/hooks/useViewport";
import React, { Suspense } from "react";

import { useZustandLocation } from "@faire/retailer/app/_lib/routing/useZustandLocation";
import { shouldShowMobileAppBanner } from "@faire/retailer/components/App/shouldShowMobileAppBanner";
import { useShouldApplyLayout } from "@faire/retailer/components/App/useShouldApplyLayout";
import { LazyMobileAppSnackbar } from "@faire/retailer/components/MobileAppSnackbar";

export const EncapsulatedMobileAppSnackbarWrapper = () => {
  const location = useZustandLocation((state) => state.pathname);

  return <MobileAppSnackbarWrapper pathname={location.pathname} />;
};

const MobileAppSnackbarWrapper = React.memo(
  ({ pathname }: { pathname: string }) => {
    const viewport = useViewport();
    const shouldApplyLayout = useShouldApplyLayout();

    if (!viewport.isMobile || !shouldApplyLayout) {
      return null;
    }

    if (!shouldShowMobileAppBanner(pathname)) {
      return null;
    }

    return (
      <Suspense fallback={null}>
        <LazyMobileAppSnackbar />
      </Suspense>
    );
  }
);
