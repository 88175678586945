import { Language } from "@faire/web-api/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api/indigofair/locale/ILocaleKey";
import { getTwoLetterLanguageCode } from "@faire/web/common/consts/THREE_TO_TWO_LETTER_LANGUAGE_CODE";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";
import { convertLocaleToCountryLocaleString } from "@faire/web/common/localization/languageWithCountryOptions";
import { useLocalization } from "@faire/web/common/localization/useLocalization";
import { isPrerender } from "@faire/web/common/user-agent/isPrerender";
import { RoutingContext } from "@faire/web/ui/routing/RoutingContext";
import React from "react";

import { getFaireDirectBrandAlias } from "./serialized-data/getFaireDirectBrandAlias";
import { getFaireDirectBrandToken } from "./serialized-data/getFaireDirectBrandToken";

export const RetailerRoutingContextProvider = (
  props: React.PropsWithChildren
) => {
  const {
    intl: { locale },
  } = useLocalization();
  const canonicalLocale = getTwoLetterLanguageCode(Language.ENG).toLowerCase();
  const localeCountryUrlPrefix: string | undefined = getGlobalProperty(
    "localeCountryUrlPrefix"
  );
  const availableLanguageSelectorLocales = (
    getGlobalProperty<ILocaleKey[]>("availableLanguageSelectorLocales") ?? []
  ).map((iLocaleKey) => convertLocaleToCountryLocaleString(iLocaleKey));

  const faireDirectBrandToken = getFaireDirectBrandToken();
  const faireDirectBrandAlias = getFaireDirectBrandAlias();

  return (
    <RoutingContext.Provider
      value={{
        locale,
        canonicalLocale,
        localeCountryUrlPrefix,
        allowedRobotLocales: availableLanguageSelectorLocales,
        ...(!isPrerender()
          ? { faireDirectBrandAlias, faireDirectBrandToken }
          : null),
      }}
    >
      {props.children}
    </RoutingContext.Provider>
  );
};

export const useRetailerRoutingContext = () => {
  return React.useContext(RoutingContext);
};
