"use client";

import { trackRetailersReactAppInitView } from "@faire/web-api/events/retailers/view/reactAppInit";
import { trackSessionsLocalusertimeView } from "@faire/web-api/events/sessions/view/localusertime";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";
import { getWindow } from "@faire/web/common/globals/getWindow";
import { HistoryTiming } from "@faire/web/common/performance/HistoryTiming";
import { ResourceTimingCollector } from "@faire/web/common/performance/ResourceTimingCollector";
import { reloadExpiredSessions } from "@faire/web/common/releaseReloader";
import { initializeScrollbarWidth } from "@faire/web/common/scrollbarSize";
import { setImageDPR } from "@faire/web/common/transform-images";
import { History } from "history";
import once from "lodash/once";
import React, { lazy, Suspense, useLayoutEffect } from "react";

import { initializeAutoSignUp } from "@faire/retailer/initializers/autoSignUp";
import { fetchBrandRetailer } from "@faire/retailer/initializers/fetchBrandRetailer";
import { startLocationLogging } from "@faire/retailer/initializers/locationLogging";
import { watchForRedirects } from "@faire/retailer/initializers/watchForRedirects";
import { updateEmployeeState } from "@faire/retailer/lib/employee";
import { handleComponentLoadError } from "@faire/retailer/lib/loadChunkErrors";
import { isChromeLighthouse } from "@faire/retailer/lib/user-agent/isChromeLighthouse";
import { PageLoadEvent } from "@faire/retailer/services/performance/const";
import { PerformanceMarker } from "@faire/retailer/services/performance/PerformanceMarker";
import { Checkpoints } from "@faire/retailer/stores/Checkpoints";
import { ElevatePortalStore } from "@faire/retailer/stores/domain/ElevatePortalStore";
import { UserStore } from "@faire/retailer/stores/domain/UserStore";

const initializeApp = once((history: History) => {

  const recordLifecycleEvents = getGlobalProperty(
    "recordLifecycleEvents",
    false
  );
  if (recordLifecycleEvents) {
    const htmlSource = "nextjs";
    trackRetailersReactAppInitView(
      htmlSource,
      getGlobalProperty("routeProductArea", ""),
      getGlobalProperty("templatedPath", "")
    );
  }
  const performanceMarker = PerformanceMarker.get();
  performanceMarker.init();
  performanceMarker.mark(PageLoadEvent.appStart);
  const dpr = isChromeLighthouse() ? 1 : getWindow()?.devicePixelRatio;
  setImageDPR(dpr);

  fetchBrandRetailer();
  startLocationLogging(history);
  watchForRedirects(history);
  reloadExpiredSessions();

  initializeScrollbarWidth();

  // We used to await this in the legacy app, but blocking doesn't seem to be necessary.
  ElevatePortalStore.get().waitToInitialize();

  updateEmployeeState(UserStore.get().user);

  initializeAutoSignUp(history);

  trackSessionsLocalusertimeView(`${new Date().valueOf()}`);
  ResourceTimingCollector.get().initialize();
  HistoryTiming.get().initialize(history);

  performanceMarker.mark(PageLoadEvent.appInitialized);
  Checkpoints.get().scheduleCheckpoints();
});

/**
 * Executes the necessary steps of src/main.tsx and src/initializeApp.ts
 * on the client of the nextjs app.
 */
const useAppInitialization = (memoryHistory: History) => {
  useLayoutEffect(() => {
    initializeApp(memoryHistory);
  }, [memoryHistory]);
};

const LazyPostInteractiveInitializer = lazy(() =>
  import(
    /* webpackChunkName: "post-app-initializer" */ "./PostInteractiveInitializer"
  )
    .then((m) => ({
      default: m.PostInteractiveInitializer,
    }))
    .catch(handleComponentLoadError)
);

export const AppInitializer: React.FC<{ history: History }> = ({ history }) => {
  useAppInitialization(history);

  return (
    <Suspense fallback={null}>
      <LazyPostInteractiveInitializer />
    </Suspense>
  );
};
