import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  button {
    cursor: pointer;
  }

  textarea {
    resize: none;
  }
`;
