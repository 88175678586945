import { route as brandRoute } from "@faire/web-api/routes/brand/brandToken";
import { route as c1Route } from "@faire/web-api/routes/category/c1CategoryUrlId";
import { route as c1BrandValueRoute } from "@faire/web-api/routes/category/c1CategoryUrlId/brand-value/brandValue";
import { route as c2Route } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId";
import { route as c2BrandValueRoute } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/brand-value/brandValue";
import { route as c3Route } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId";
import { route as c3BrandValueRoute } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/brand-value/brandValue";
import { route as discoverRoute } from "@faire/web-api/routes/discover/searchTerm";
import { route as exploreRoute } from "@faire/web-api/routes/explore/searchTerm";
import { route as collectionRoute } from "@faire/web-api/routes/product-based-collection/product_based_collection_token";
import { route as productRoute } from "@faire/web-api/routes/product/productToken";
import { route as homeRoute } from "@faire/web-api/routes/root";
import { route as searchRoute } from "@faire/web-api/routes/search";
import { route as suppliersC1Route } from "@faire/web-api/routes/suppliers/c1SuppliersUrlId";
import { route as suppliersC1BrandValueRoute } from "@faire/web-api/routes/suppliers/c1SuppliersUrlId/brand-value/brandValue";
import { route as suppliersC2Route } from "@faire/web-api/routes/suppliers/c1SuppliersUrlId/subcategory/c2SuppliersUrlId";
import { route as suppliersC3Route } from "@faire/web-api/routes/suppliers/c1SuppliersUrlId/subcategory/c2SuppliersUrlId/c3SuppliersUrlId";
import { delay } from "@faire/web/common/delay";
import {
  getWindow,
  getWindowOrThrow,
} from "@faire/web/common/globals/getWindow";
import { getCurrentPath } from "@faire/web/common/Path";
import { RouteMatcher } from "@faire/web/common/routes/RouteMatcher";
import { Storage } from "@faire/web/common/Storage";
import { removeLocaleFromUrl } from "@faire/web/ui/routing/util";
import { History } from "history";

import {
  notifySignupSnackbarEvent,
  SignUpSnackbarEvent,
} from "@faire/retailer/components/LoggedOutMobileSignUpSnackbar/useSignupSnackbarStateMachine";
import { isLoggedInRetailerOrBrandPreview } from "@faire/retailer/lib/isLoggedInRetailer";
import { isComingFromFacebookOrInstagram } from "@faire/retailer/lib/navigation-origin/isComingFromFacebookOrInstagram";
import {
  popSignUp,
  SignUpElement,
  SignUpType,
} from "@faire/retailer/lib/popSignUp";
import { getIsProduction } from "@faire/retailer/serialized-data/getEnvName";
import {
  assignSettingSecondPageAutoModal,
  getSettingSecondPageAutoModal,
} from "@faire/retailer/settings/getSettingSecondPageAutoModal";
import { AppModalStore } from "@faire/retailer/stores/ui/AppModalStore";

import { HAS_CLOSED_SIGNUP_MODAL } from "../components/AppSignUp/state/useHandleModalState";
import { getFaireDirectBrandToken } from "../serialized-data/getFaireDirectBrandToken";

let popSignUpCount: number = 2;
let ignoreNextScroll: boolean = false;
let prevPathname: string | undefined = undefined;

export const initializeAutoSignUp = async (history: History) => {
  getWindow()?.addEventListener("scroll", () => {
    // we only want to react to the scroll event if the user has first navigated to a different page
    if (
      !ignoreNextScroll &&
      !isSamePathname(getCurrentPath().pathname, prevPathname)
    ) {
      autoSignUp();
    }
    ignoreNextScroll = false;
  });

  history.listen((location) => {
    if (!isSamePathname(location.pathname, prevPathname)) {
      prevPathname = getCurrentPath().pathname;
      // this is to handle cases where a user has scrolled partway down the page, and then navigates
      //   this causes a scroll event (to top of page) which in turn triggers the autoSignUp
      //   we want to ignore this scroll event
      if (getWindow()?.scrollY !== 0) {
        ignoreNextScroll = true;
      }
    }
  });

  // If we've scrolled before page load (i.e., while browsing SSR content),
  // we want to behave as if debouncedAutoSignUp() was called.
  const lastScrollBeforePageLoad = getWindow()?.mostRecentScrollTime;
  if (lastScrollBeforePageLoad) {
    if (lastScrollBeforePageLoad === getWindow()?.mostRecentScrollTime) {
      await delay(0);
      autoSignUp();
    }
  }
};

export const autoSignUp = () => {
  if (shouldPopSignUp()) {
    notifySignupSnackbarEvent(SignUpSnackbarEvent.AUTO_SIGNUP_OPENED);

    if (popSignUpCount > 0) {
      popSignUpCount--;
    }

    if (getSettingSecondPageAutoModal() && popSignUpCount === 0) {
      getWindowOrThrow().removeEventListener("scroll", autoSignUp);
    }

    popSignUp(SignUpType.AUTO, () => {}, {
      element: SignUpElement.AUTO_POPUP,
      sourceId: "auto_signup",
    });
  }
};

const shouldPopSignUp = () => {
  const matcher = new RouteMatcher(whitelist);
  const isFaireDirectActiveInLoggedOut = !!getFaireDirectBrandToken();
  const canShowSecondPageModal =
    popSignUpCount > 0 &&
    !isSamePathname(getCurrentPath().pathname, prevPathname);
  const hasSignupModalClosed = getSettingSecondPageAutoModal()
    ? !canShowSecondPageModal
    : Storage.session.getItem(HAS_CLOSED_SIGNUP_MODAL);

  // attempting to see modal for the second time
  if (popSignUpCount === 1) {
    // if user has already navigated to a different page, we can assign the setting
    if (canShowSecondPageModal) {
      assignSettingSecondPageAutoModal();
    }
    // if control group, we don't want to show the modal again
    if (!getSettingSecondPageAutoModal()) {
      getWindowOrThrow().removeEventListener("scroll", autoSignUp);
    }
  }

  return (
    getIsProduction() &&
    !isLoggedInRetailerOrBrandPreview() &&
    !isComingFromFacebookOrInstagram() &&
    !isFaireDirectActiveInLoggedOut &&
    !AppModalStore.get().isModalShowing &&
    matcher.match(getCurrentPath().pathname) &&
    !hasSignupModalClosed
  );
};

const whitelist = [
  homeRoute,
  brandRoute,
  productRoute,
  collectionRoute,
  searchRoute,
  discoverRoute,
  exploreRoute,
  c1Route,
  c1BrandValueRoute,
  c2Route,
  c2BrandValueRoute,
  c3Route,
  c3BrandValueRoute,
  suppliersC3Route,
  suppliersC1Route,
  suppliersC1BrandValueRoute,
  suppliersC2Route,
];

const isSamePathname = (
  p1: string | undefined,
  p2: string | undefined
): boolean => {
  if (p1 === undefined || p2 === undefined) {
    return p1 === p2;
  }
  return removeLocaleFromUrl(p1) === removeLocaleFromUrl(p2);
};

export const resetAutoSignUpState = () => {
  popSignUpCount = 2;
  ignoreNextScroll = false;
};
