import { route as brandPageRoute } from "@faire/web-api/routes/brand/brandToken";
import { pathMatchesRoute } from "@faire/web/common/routes/RouteMatcher";
import { Storage } from "@faire/web/common/Storage";
import { matchPath } from "react-router-dom";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { BANNER_FLAG_TIMESTAMP } from "@faire/retailer/services/MobileAppBanner";
import { getSettingAppMigrationAvailable } from "@faire/retailer/settings/getSettingAppMigrationAvailable";
import {
  MOBILE_APP_PROMOTION_ALLOWED_PATHS,
  MOBILE_APP_PROMOTION_BLOCKED_PATHS,
} from "@faire/retailer/stores/ui/PromoModalStore";

export const shouldShowMobileAppBanner = (pathname: string) => {
  const brandPagePartialBlock =
    pathMatchesRoute(pathname, brandPageRoute) && isLoggedInRetailer();
  const isLocationOnAllowedList = MOBILE_APP_PROMOTION_ALLOWED_PATHS.some(
    (path) => !!matchPath(pathname, { path, exact: true })
  );
  const isLocationOnBlockedList = MOBILE_APP_PROMOTION_BLOCKED_PATHS.some(
    (path) => !!matchPath(pathname, { path, exact: true })
  );
  const isLocationAllowed =
    isLocationOnAllowedList &&
    !isLocationOnBlockedList &&
    !brandPagePartialBlock;

  const dismissedUntil = Storage.local.getItem(BANNER_FLAG_TIMESTAMP);
  const isDismissed = !!dismissedUntil && Number(dismissedUntil) >= Date.now();

  return (
    isLoggedInRetailer() &&
    isLocationAllowed &&
    getSettingAppMigrationAvailable() &&
    !isDismissed
  );
};
