import { delay } from "@faire/web/common/delay";
import { getWindowOrThrow } from "@faire/web/common/globals/getWindow";

import { waitForFrameDelayBelowThreshold } from "@faire/retailer/services/performance/lib";

export function requestIdleCallbackOrFallback(
  callback: () => void,
  timeout: number = 2000
): void {
  const { requestIdleCallback } = getWindowOrThrow();
  if (requestIdleCallback) {
    requestIdleCallback(callback, {
      timeout,
    });
  } else {
    Promise.race([
      delay(timeout),
      new Promise<void>((resolve) =>
        waitForFrameDelayBelowThreshold(100, resolve)
      ),
    ]).then(callback);
  }
}
