import { IUser } from "@faire/web-api/indigofair/data/IUser";

import { RetailerStore } from "@faire/retailer/stores/domain/RetailerStore";
import { UserStore } from "@faire/retailer/stores/domain/UserStore";

// fetch brand users "retailer" for 'View My Shop'
export const fetchBrandRetailer = async () => {
  const { user } = UserStore.get();
  if (!user || user.type !== IUser.Type.BRAND_USER) {
    return;
  }

  await RetailerStore.get().refetchRetailer(`r_${user.brand_token}`);
};
