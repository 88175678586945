import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { buildServerCartQueryKey, useRelevantQueryParams } from "./utils";

export const useInvalidateServerCart = () => {
  const queryClient = useQueryClient();
  const queryParams = useRelevantQueryParams();

  const invalidateServerCart = useCallback(
    (savedForLater: boolean) => {
      return queryClient.invalidateQueries({
        queryKey: buildServerCartQueryKey(queryParams, savedForLater),
        refetchType: "all",
      });
    },
    [queryClient, queryParams]
  );

  const invalidateActiveServerCart = useCallback(
    () => invalidateServerCart(false),
    [invalidateServerCart]
  );
  const invalidateSavedServerCart = useCallback(
    () => invalidateServerCart(true),
    [invalidateServerCart]
  );

  return { invalidateActiveServerCart, invalidateSavedServerCart };
};
