import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { CartsTabs } from "@faire/retailer/stores/domain/CartStore/consts";

import {
  buildServerCartSummaryQueryKey,
  useRelevantQueryParams,
} from "./utils";

export const useInvalidateServerCartSummary = () => {
  const queryClient = useQueryClient();
  const queryParams = useRelevantQueryParams();

  const invalidateServerCartSummary = useCallback(
    (selectedBrandTokens: string[], activeTab: CartsTabs) => {
      return queryClient.invalidateQueries({
        queryKey: buildServerCartSummaryQueryKey(
          queryParams,
          selectedBrandTokens,
          activeTab
        ),
        refetchType: "all",
      });
    },
    [queryClient, queryParams]
  );

  const invalidateActiveServerCartSummary = useCallback(
    (selectedBrandTokens: string[]) =>
      invalidateServerCartSummary(selectedBrandTokens, CartsTabs.ALL_BRAND),
    [invalidateServerCartSummary]
  );
  const invalidateSavedServerCartSummary = useCallback(
    (selectedBrandTokens: string[]) =>
      invalidateServerCartSummary(selectedBrandTokens, CartsTabs.SAVED_FOR_LATER),
    [invalidateServerCartSummary]
  );

  return { invalidateActiveServerCartSummary, invalidateSavedServerCartSummary};
};
