import React from "react";
import { useSettingNuxAppDownloadNudges } from "@faire/retailer/settings/useSettingNuxAppDownloadNudges";
import { EncapsulatedMobileAppSnackbarWrapper } from "@faire/retailer/components/App/EncapsulatedMobileAppSnackbarWrapper";
import { AppDownloadNudgeWrapper } from "@faire/retailer/components/AppDownloadNudge/AppDownloadNudgeWrapper";

export const AppDownloadWrapper = () => {
  const isNuxAppDownloadNudgesEnabled = useSettingNuxAppDownloadNudges();

  return isNuxAppDownloadNudgesEnabled ? (
    <AppDownloadNudgeWrapper />
  ) : (
    <EncapsulatedMobileAppSnackbarWrapper />
  );
};
