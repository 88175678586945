import { CartsTabs } from "@faire/retailer/stores/domain/CartStore/consts";
import { QueryParameters } from "@faire/web-api/routes/cart";
import { useQueryParams } from "@faire/web/ui/hooks/useQueryParams";
import pick from "lodash/pick";

const RELEVANT_QUERY_PARAMS: (keyof QueryParameters)[] = ["filters", "query"];

export const useRelevantQueryParams = () =>
  pick(useQueryParams(QueryParameters.parse), RELEVANT_QUERY_PARAMS);

export const buildServerCartQueryKey = (
  queryParams: QueryParameters,
  savedForLater: boolean
) => {
  return ["ServerBrandCarts", queryParams, savedForLater];
};

export const buildServerCartSummaryQueryKey = (
  queryParams: QueryParameters,
  selectedBrandTokens: string[],
  activeTab: CartsTabs
) => {
  return ["ServerCartSummary", queryParams, selectedBrandTokens, activeTab];
};
