import { route as brandRoute } from "@faire/web-api/routes/brand/brandToken";
import { route as c1Route } from "@faire/web-api/routes/category/c1CategoryUrlId";
import { route as c2Route } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId";
import { route as c3Route } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId";
import { route as checkoutSuccessRoute } from "@faire/web-api/routes/checkout/success/orderToken";
import { route as productRoute } from "@faire/web-api/routes/product/productToken";
import { route as searchRoute } from "@faire/web-api/routes/search";
import { route as ordersRoute } from "@faire/web-api/routes/user/account/orders";
import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { useViewport } from "@faire/web/ui/hooks/useViewport";
import { useGetMobileAppUrl } from "./useGetMobileAppUrl";
import { RawQueryParams } from "@faire/web/common/query-params/QueryParams";
import { useGetNudgeDismissInfo } from "./useGetNudgeDismissInfo";
import { RetailerRoute } from "@faire/web/common/routes/RetailerRouteMatcher";
import { RouteMatcher } from "@faire/web/common/routes/RouteMatcher";

const APP_DOWNLOAD_NUDGE_ALLOWED_PATHSL: RetailerRoute[] = [
  searchRoute,
  c1Route,
  c2Route,
  c3Route,
  productRoute,
  brandRoute,
  ordersRoute,
  checkoutSuccessRoute,
];

// hook to validate if the app download nudge can be shown
// api/retailer/show-nux-app-download-nudge/get: if the nudge should be shown
export const useCanShowAppDownloadNudge = (pathname: string, queryParams?: RawQueryParams) => {
  const { isMobile } = useViewport();
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const ctaUrl = useGetMobileAppUrl(queryParams);
  const { isDismissOverLimit } = useGetNudgeDismissInfo();

  const allowedRouteMatcher: RouteMatcher<RetailerRoute> = new RouteMatcher(APP_DOWNLOAD_NUDGE_ALLOWED_PATHSL);
  const isLocationOnAllowedList = !!allowedRouteMatcher.match(pathname);

  return (
    isMobile &&
    isLoggedInRetailer &&
    isLocationOnAllowedList &&
    !!ctaUrl &&
    !isDismissOverLimit
  );
};
