import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSettingAppPromotionBannerLinks } from "@faire/retailer/settings/useSettingAppPromotionBannerLinks";
import { getDeepLinkUrl, OSType } from "@faire/retailer/services/MobileAppBanner";
import { getOsName } from "@faire/retailer/lib/user-agent/osName";
import { getIsAppInstalled } from "@faire/retailer/serialized-data/getIsAppInstalled";
import { RawQueryParams } from "@faire/web/common/query-params/QueryParams";

export const useGetMobileAppUrl = (queryParams?: RawQueryParams) => {
  const location = useLocation();
  const storeLinks = useSettingAppPromotionBannerLinks();

  const deepLinkUrl = getDeepLinkUrl(location, queryParams);
  const storeLinkUrl = useMemo(() => {
    if (getOsName() === OSType.IOS) {
      return storeLinks.app_store_link ?? "";
    } else if (getOsName() === OSType.ANDROID) {
      return storeLinks.play_store_link ?? "";
    }

    return "";
  }, [storeLinks.app_store_link, storeLinks.play_store_link]);

  const ctaUrl = useMemo((): string => (
    (!!getIsAppInstalled() && deepLinkUrl) || storeLinkUrl
  ), [deepLinkUrl, storeLinkUrl]);

  return ctaUrl;
};
