import { route as cartRoute } from "@faire/web-api/routes/cart";
import { RouteMatcher } from "@faire/web/common/routes/RouteMatcher";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useCartPageStore } from "@faire/retailer/features/Cart/CartPageStore";
import { useSettingCartRSC } from "@faire/retailer/settings/useSettingCartRSC";

/**
 * For the RSC experience, the initial page content is rendered on the server.
 * The cart query data can be "lazy" fetched on the client, so we disable the queries
 * until the page is loaded.
 */
export const useShouldDisableCartQueries = () => {
  const isCartRSC = useSettingCartRSC();
  const pathname = useLocation().pathname;
  const [{ hasPageLoaded }] = useCartPageStore(["hasPageLoaded"]);

  const isOnCartPage = useMemo(() => {
    const matcher = new RouteMatcher([cartRoute]);
    return !!matcher.match(pathname);
  }, [pathname]);

  return isCartRSC && isOnCartPage && !hasPageLoaded;
};
