import { IBrandCartV2 } from "@faire/web-api/faire/carts/IBrandCartV2";

import {
  CartsTabs,
  IBrandCartMovingInfo,
  IBrandCartV2WithSummary,
} from "@faire/retailer/stores/domain/CartStore/consts";

import { CartSortByValue } from "../components/CartSortPill/types";

export enum BrandCartTransitionType {
  NONE = "NONE",
  SAVE_FOR_LATER = "SAVE_FOR_LATER",
  MOVE_TO_BAG = "MOVE_TO_BAG",
  DELETE = "DELETE",
}

export type BrandCartsTransitionState = {
  brandCarts: IBrandCartV2[];
  type: BrandCartTransitionType;
};

export type CartPageStore = {
  // Search & filter states
  activeTab: CartsTabs;
  searchFieldValue: string;
  sortByValue: CartSortByValue;
  isFilterByOverMinimum: boolean;
  isFilterByUnderMinimum: boolean;
  brandTokenOrder: Map<string, number> | undefined;
  // Transition states
  brandCartTransitionState: BrandCartsTransitionState;
  isBrandCartTransitioning: boolean;
  brandCartDetailsShownMap: Map<string, boolean>;
  brandCartMovingMap: Map<string, IBrandCartMovingInfo>;
  deletedBrandCartDataMap: Map<string, IBrandCartV2WithSummary>;
  selectedBrandTokens: string[];
  focusedBrandToken: string | undefined;
  brandCartRefs: React.MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
  // Mobile states
  showingMobileDetailsPageForBrand: string | undefined;
  // Error states
  brandsWithShippingDateIssue: string[];
  errorMessage: string | undefined;
  // Toast states
  isUndoToastVisible: boolean;
  // Initialization
  hasPageLoaded: boolean;
  hasInitializedDetailsShownMap: boolean;
};
