import { makeObservable } from "@faire/web/common/makeObservable";
import { isWindowUndefined } from "@faire/web/common/server-side-rendering/isWindowUndefined";
import { singletonGetter } from "@faire/web/common/singletons/getter";
import once from "lodash/once";
import { action, observable, when } from "mobx";

import { PageInitialized } from "@faire/retailer/events";
import { setTimeoutIfPossible } from "@faire/retailer/lib/async-functions/setTimeoutIfPossible";
import { requestIdleCallbackOrFallback } from "@faire/retailer/lib/requestIdleCallbackOrFallback";
import { isChromeLighthouse } from "@faire/retailer/lib/user-agent/isChromeLighthouse";

/**
 * This store keeps track of app level checkpoints to be
 * consumed by components and other services that may
 * be executing during page load and want to defer their
 * logic so as to not slow page load itself
 * @deprecated prefer to use `useCheckpoints` or `withStores` instead
 */
export class Checkpoints {
  constructor() {
    makeObservable(this);
    this._whenAppIsInteractive = when(() => this.appIsInteractive);
    this._whenEventSubscribersReady = when(() => this.eventSubscribersReady);
  }

  static get = singletonGetter(Checkpoints);

  // This is an approximation of a measure we want to hone over time
  // https://developers.google.com/web/tools/lighthouse/audits/time-to-interactive
  @observable
  appIsInteractive: boolean = false;

  // Event subscribers are finished initializing themselves (so publishing is free to go).
  @observable
  private eventSubscribersReady: boolean = false;

  private pageInteractiveEstimateTimerId?: number;

  // We keep track of this to enable some deviations in the scheduling of our checkpoints
  // specifically to improve our page speed score.
  private isChromeLighthouse: boolean = isChromeLighthouse();

  private _whenAppIsInteractive: Promise<void> & {
    cancel(): void;
  };
  private _whenEventSubscribersReady: Promise<void> & {
    cancel(): void;
  };

  whenAppIsInteractive = () => {
    return this._whenAppIsInteractive;
  };
  whenEventSubscribersReady = () => {
    return this._whenEventSubscribersReady;
  };

  scheduleCheckpoints = once(
    (
      estimatedTimeToInteractive: number = this.isChromeLighthouse
        ? 10000
        : 5000
    ) => {
      if (isWindowUndefined()) {
        return;
      }
      // To schedule the 'interactive' checkpoint, we first schedule a timeout for X seconds from
      // now. This is a reasonable estimate for a time in in the future that will be at least a few
      // seconds after the page really initialized. On very slow connections, it will fire before the
      // page is really initialized. On fast connections, it will happen long after the page is really
      // initialized.
      //
      // Pages can get more accurate timing of this by publishing a PageInitialized event when the
      // critical rendering is complete.
      this.pageInteractiveEstimateTimerId = setTimeoutIfPossible(() => {
        this.setAppIsInteractiveWhenIdle();
      }, estimatedTimeToInteractive);

      // Some pages (e.g. brand page) post this event when they are done initializing. We subscribe
      // to this event here so that we can set the interactive checkpoint at a more accurate time.
      PageInitialized.subscribe(this.onPageInitDoneEvent);
    }
  );

  // unused-class-members-ignore-next
  @action
  markEventSubscribersReady = () => {
    this.eventSubscribersReady = true;
  };

  private setAppIsInteractiveWhenIdle = () => {
    requestIdleCallbackOrFallback(() => this.setAppIsInteractive());
  };

  @action
  private setAppIsInteractive = () => {
    this.appIsInteractive = true;
  };

  private onPageInitDoneEvent = () => {
    if (!this.appIsInteractive) {
      clearTimeout(this.pageInteractiveEstimateTimerId);
      // We use a much longer time for Chrome-Lighthouse UA (Google page speed bot). We do this
      // because otherwise the late-init module pops up 1 second after things settle (but the bot is
      // still listening for activity) and then the entire late-init flow ends up counting against
      // page speed score.
      const delayFromPageInit = this.isChromeLighthouse ? 10000 : 1000;
      setTimeoutIfPossible(() => {
        this.setAppIsInteractiveWhenIdle();
      }, delayFromPageInit);
    }
    PageInitialized.unsubscribe(this.onPageInitDoneEvent);
  };
}
// export const useCheckpoints = createStoreHook(Checkpoints);
