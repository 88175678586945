import { useQuery as useProductBestImageQuery } from "@faire/web-api/api/product/productToken/best-image/get-hooks";
import { useQuery as useSEOSignUpModalQuery } from "@faire/web-api/api/seo/sign-up-modal/get-hooks";
import { trackFaireDirectShoppableLandingPageModalCloseClick } from "@faire/web-api/events/faireDirect/click/shoppableLandingPageModalClose";
import { trackSignupCloseSignUpModalClick } from "@faire/web-api/events/signup/click/closeSignUpModal";
import { match as matchProductRoute } from "@faire/web-api/routes/product/productToken/match";
import { QueryParameters as SignUpQueryParameters } from "@faire/web-api/routes/welcome/r/personal";
import { Storage } from "@faire/web/common/Storage";
import { useQueryParams } from "@faire/web/ui/hooks/useQueryParams";
import qs from "qs";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { recordSignupActivity } from "@faire/retailer/actions/user/recordLoggedOutActivity";
import { useFaireDirectBrand } from "@faire/retailer/hooks/FaireDirect/useFaireDirectBrand/useFaireDirectBrand";
import { useLeanProductWithMaybeReactQuery } from "@faire/retailer/views/product/utils/useLeanProductQuery";

import {
  notifySignupSnackbarEvent,
  SignUpSnackbarEvent,
} from "../../LoggedOutMobileSignUpSnackbar/useSignupSnackbarStateMachine";
import {
  isFaireDirectVariant,
  isAutoSignUpVariant,
  determineAppSignUpModalVariant,
  AppSignUpModalVariant,
} from "../AppSignUpModalVariant";

export const HAS_CLOSED_SIGNUP_MODAL = "has-closed-signup-modal";

const EMPTY_QUERY_FIELDS = {
  signUp: undefined,
  product: undefined,
  brand: undefined,
  video: undefined,
  source: undefined,
  email: undefined,
  refB: undefined,
  refC: undefined,
  refF: undefined,
  refS: undefined,
  faireMarket: undefined,
};

export const useHandleModalState = () => {
  const history = useHistory();
  const location = useLocation();
  const { faireDirectBrandToken } = useFaireDirectBrand();
  const signUpParams = useQueryParams(SignUpQueryParameters.parse);

  const productToken = matchProductRoute(location.pathname)?.params
    .productToken;
  // If we're on the PDP, we need to get the /api/product/productToken response here in order to
  // handle proper 'FD from marketplace' assignment. Note that this query should ONLY be used on th PDP,
  // and should not kick off a new request, instead it should read from the cache as we've already
  // fetched this product on the PDP.
  const productPageResponse = useLeanProductWithMaybeReactQuery(productToken);

  const modalVariant = useMemo(
    () =>
      determineAppSignUpModalVariant(
        signUpParams,
        location,
        productPageResponse
      ),
    [location, signUpParams, productPageResponse]
  );

  const {
    data: signUpModalProductImageResponse,
    isFetching: isSEOSignUpModalQueryLoading,
    refetch: fetchSignUpModalProductImage,
  } = useSEOSignUpModalQuery(undefined, {
    enabled: false,
  });

  const {
    data: productBestImageResponse,
    isFetching: isProductBestImageQueryLoading,
    refetch: fetchProductBestImageQuery,
  } = useProductBestImageQuery(
    signUpParams.product || signUpParams.refP || "",
    {
      enabled: false,
    }
  );

  const handleModalClose = useCallback(() => {
    history.replace({
      ...history.location,
      search: qs.stringify({
        ...signUpParams,
        ...EMPTY_QUERY_FIELDS,
      }),
    });

    if (isFaireDirectVariant(modalVariant)) {
      trackFaireDirectShoppableLandingPageModalCloseClick();
    } else {
      trackSignupCloseSignUpModalClick();
    }

    if (isAutoSignUpVariant(modalVariant)) {
      notifySignupSnackbarEvent(SignUpSnackbarEvent.AUTO_SIGNUP_CLOSED);
    }

    Storage.session.setItem(HAS_CLOSED_SIGNUP_MODAL, "true");
  }, [history, modalVariant, signUpParams]);

  const recordModalSignUpActivity = useCallback(() => {
    // For leveraging logged out signals from product signups
    if (modalVariant === AppSignUpModalVariant.PRODUCT) {
      recordSignupActivity(
        {
          product_token: signUpParams.product,
          brand_token: signUpParams.brand,
        },
        location.pathname
      );
    } else if (isFaireDirectVariant(modalVariant)) {
      recordSignupActivity(
        {
          brand_token: faireDirectBrandToken,
        },
        location.pathname
      );
    }
  }, [
    faireDirectBrandToken,
    location.pathname,
    modalVariant,
    signUpParams.brand,
    signUpParams.product,
  ]);

  return {
    modalVariant,
    signUpModalProductImageResponse,
    productBestImageResponse,
    isFeaturedImageLoading:
      isSEOSignUpModalQueryLoading || isProductBestImageQueryLoading,
    fetchSignUpModalProductImage,
    fetchProductBestImageQuery,
    handleModalClose,
    recordModalSignUpActivity,
  };
};
