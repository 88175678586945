import { createContextStore } from "@faire/web/ui/hooks/ContextStore";

export enum AddedToBagContainer {
  BRAND_CART = "BRAND_CART",
  ADDED_TO_CART = "ADDED_TO_CART",
}

const DEFAULT_IGNORED_CONTAINERS: Record<AddedToBagContainer, boolean> = {
  BRAND_CART: false,
  ADDED_TO_CART: false,
};

const {
  useStore: useAddedToBagContextStore,
  Provider: AddedToBagContextStoreProvider,
} = createContextStore(
  {
    shouldIgnore: false,
    ignoredContainers: DEFAULT_IGNORED_CONTAINERS,
  },
  { name: "AddedToBagContextStore" }
);

export { useAddedToBagContextStore, AddedToBagContextStoreProvider };
